<div
	class="nform-picture-img-grid-dialog-container"
	layout="column"
	layout-fill
>

<!-- [formlyField]="data.formlyField" -->
	<nform-picture-img-grid
		[formlyFieldDataContent]="data.formlyField.field.data.custom_form_data_content"
		[formlyFieldDataKey]="data.formlyField.field.key"
		[formlyFieldDataModel]="data.formlyField.model"
		[formlyFieldDataFormControl]="data.formlyField.formControl"
		[formlyFieldGridStateIsDisabled]="false"
		layout="column"
		layout-align="center center"
		flex
	></nform-picture-img-grid>

	<mat-toolbar
		layout="row"
		layout-align="center center"
		flex="none"
		class="picture-actions"
		color="primary"
	>
		<button
			*ngIf="!noGrid"
			mat-icon-button
			layout="column"
			layout-align="center"
			flex="none"
			(click)="data.formlyField.reset()"
		>
			<mat-icon>replay</mat-icon>
		</button>
		<button
			mat-icon-button
			layout="column"
			layout-align="center"
			flex="none"
			(click)="close()"
		>
			<mat-icon>check_circle</mat-icon>
		</button>
	</mat-toolbar>

</div>
