import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { getThousandSeparator } from 'src/app/helpers/intl';
@Component({
	selector: 'nform-object-number',
	templateUrl: './number.component.html',
	styleUrls: ['./number.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NFormNumberComponent extends FieldType<NFormObjectFormlyFieldConfig> implements OnInit {

	static nFormObjectName = 'nform-number';

	decimal = 0;
	maxLength = 22;
	_formControl: UntypedFormControl;

	thousandSeparator = getThousandSeparator(this.translocoService.getActiveLang());

	constructor(
		private translocoService: TranslocoService

	){
		super();
	}

	ngOnInit() {
		this._formControl = this.formControl as FormControl<any>;
		this.setDecimal();
	}

	private setDecimal(){
		this.decimal =
			this.field.data.rule?.restriction_decimal
			||
			this.field.data.custom_form_data_content.decimal;
	}

	// get type() {
	// 	// return 'number';
	// 	return this.to.type || 'text';
	// }

}
