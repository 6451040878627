<div layout="column">

	<p
		*ngIf="to.floatLabel === 'paragraph'"
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<mat-form-field
		[floatLabel]="to.floatLabel || 'auto'"
		[hideRequiredMarker]="true"
		override-mat-form-field-outline-thick
	>

		<mat-label
			*ngIf="to.floatLabel !== 'paragraph' && to.label && to.hideLabel !== true"
		>
			<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
			{{ to.label }}
			<span
				*ngIf="to.required"
				theme-palette-hue-fg="warn"
			>
				*
			</span>
		</mat-label>

		<mat-select
			[attr.disabled]="to.readonly"
			[required]="to.required"
			[formControl]="_formControl"
			[placeholder]="to.placeholder"
		>
			<mat-option *ngIf="!to.required"></mat-option>
			<mat-option
				*ngFor="let option of listOptions"
				[value]="option.value"
			>
				{{option.value}}
			</mat-option>
		</mat-select>

		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>

		<div class="mat-mdc-form-field-subscript-wrapper">
			<div
				*ngIf="to.required"
				class="mat-mdc-form-field-error-wrapper fixed-required"
				theme-palette-hue-fg="warn"
			>
				{{'Form.ValidationMessage.required' | transloco}}
			</div>
		</div>

	</mat-form-field>

</div>
