<div
	layout="column"
	[ngClass]="{
		'na-active': model[keyString] === eNFormRatingbarValues.na,
		'ng-invalid': formControl.invalid,
		'ng-valid': formControl.valid,
		readonly: field.props?.readonly
	}"
	position-relative
>
	<p
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div
		layout="row"
		layout-align="center"
		class="ratingbar-value"
	>
		<span class="mat-subtitle-2">{{model[keyString]}}</span>
	</div>

	<mat-slider
		*ngIf="!loading"
		color="primary"
		[discrete]="true"
		[disabled]="field.props?.readonly || formState.disabled || model[keyString] === eNFormRatingbarValues.na"
		[min]="field.data.custom_form_data_content.min"
		[max]="field.data.custom_form_data_content.max"
		[step]="stepSize"
	>
		<input
			#matSliderInput
			[(ngModel)]="model[keyString]"
			(dragEnd)="setValue(model[keyString])"
			(dragStart)="applyBlurOnSlider()"
			matSliderThumb
		/>
	</mat-slider>

	<div
		layout="row"
		layout-align="space-between"
		class="ratingbar-min-max"
	>
		<span class="mat-subtitle-2">{{field.data.custom_form_data_content.min}}</span>
		<span class="mat-subtitle-2">{{field.data.custom_form_data_content.max}}</span>
	</div>

	<div
		layout="row"
		layout-align="end"
		class="ratingbar-footer"
	>

		<div class="mat-mdc-form-field-subscript-wrapper">
			<mat-error>
				<formly-validation-message [field]="field"></formly-validation-message>
			</mat-error>
		</div>

		<div
			*ngIf="to.required"
			class="mat-mdc-form-field-subscript-wrapper fixed-required"
			theme-palette-hue-fg="warn"
		>
			{{'Form.ValidationMessage.required' | transloco}}
		</div>

		<!-- <div *ngIf="!to.required"></div> -->

		<mat-slide-toggle
			*ngIf="field.data.required === 0"
			color="primary"
			[checked]="model[keyString] === eNFormRatingbarValues.na"
			[disabled]="formState.disabled || field.props?.readonly"
			(change)="naChange($event)"
		>
			{{'Components.NForm.RATINGBAR.na' | transloco}}
		</mat-slide-toggle>
	</div>

</div>
