<div layout="column">

	<p
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div
		layout="row"
		layout-align="center"
		class="img-wrapper"
	>
		<nform-picture-img-grid
			(click)="openDialog()"
			[formlyFieldDataContent]="self.field.data.custom_form_data_content"
			[formlyFieldDataKey]="self.field.key"
			[formlyFieldDataModel]="self.model"
			[formlyFieldDataFormControl]="self.formControl"
			[formlyFieldGridStateIsDisabled]="field.props?.readonly"
			[openFullscreen]="device.phone"
		></nform-picture-img-grid>
	</div>

	<div
		*ngIf="to.required"
		class="mat-form-field-subscript-wrapper"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</div>
