import { Injectable } from '@angular/core';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { NFormAPIJsonFormDataFields, NFormObjects } from 'src/app/api-services/portal/nform-nonuser.interface';
import { UntypedFormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class ObjectExtraInfoService {

	eObjectModel = ObjectModel;
	model: ObjectServiceModel = {};
	blobPictures: ObjectServiceBlobPictures = {};

	eNFormObjects = NFormObjects;

	constructor(
	) {}

	objectServiceModel(
		objectModel: ObjectModel,
		formSeq: number,
		propertyValue: any,
		pictureOrder?: keyof ModelPictures
	)
	{
		this.model[formSeq] = this.model[formSeq] || {};

		if(objectModel === this.eObjectModel.comment) {
			this.model[formSeq].comment = propertyValue || null;
		}

		else if(objectModel === this.eObjectModel.pictures) {
			this.model[formSeq].pictures = this.model[formSeq].pictures || {};
			this.model[formSeq].pictures[pictureOrder] = propertyValue;

			this.photoCount(formSeq);
		}
	}

	checkFormNC(val: string | number, field: NFormObjectFormlyFieldConfig) {
		const formSeq = field.data?.custom_form_seq;

		if(field.data?.nc[val] === "1") {
			this.model[formSeq].nc = true;
		} else {
			this.model[formSeq].nc = false;
		}
	}

	checkDisabledNC(nFormType: NFormObjects, field: NFormObjectFormlyFieldConfig) {
		if(
			(nFormType === this.eNFormObjects.RATINGIMAGE
			||
			nFormType === this.eNFormObjects.RATINGBAR
			||
			nFormType === this.eNFormObjects.CHECKBOX)
			&&
			field.data?.nc[field.formControl.value] === "1"
		) {
			return true;
		} else if(field.props?.readonly) {
			return true;
		} else {
			return false;
		};
	}

	photoCount(formSeq: number, fieldData: NFormAPIJsonFormDataFields = null) {

		const fieldDataWaitingPhotos: boolean[] = [];

		if(fieldData){
			fieldDataWaitingPhotos.push(
				fieldData.data_photo1_waiting,
				fieldData.data_photo2_waiting,
				fieldData.data_photo3_waiting,
				fieldData.data_photo4_waiting
			)
		}

		const picturesModel = this.model[formSeq].pictures;
		const picturesModelArray = Object.values(picturesModel);
		const picturesModelFiltered = picturesModelArray.filter(
			(x, index) => x !== '' && x !== null || fieldDataWaitingPhotos?.[index]
		).length;

		this.model[formSeq].picturesCount = this.model[formSeq].picturesCount || 0;
		this.model[formSeq].picturesCount = picturesModelFiltered;
	}

	rulePictureUpload(
		c: UntypedFormControl,
		field: NFormObjectFormlyFieldConfig
	) {
		return new Promise(resolve => {
			setTimeout(() => {
				let resolveValidator: boolean = null;

				const formSeq = field.data?.custom_form_seq;

				if(field.data?.require_photo_on_nc === 0) {
					resolveValidator = true;
				}

				if(
					field.data?.require_photo_on_nc
					&&
					this.model[formSeq].nc
				) {
					resolveValidator = this.model[formSeq].picturesCount > 0;
				} else {
					resolveValidator = true;
				}

				resolve(resolveValidator);
			});
		});
	}

	nFormExtraInfoModelConstructor(formSeq: number): NFormFieldExtraInfoData {

		const modelSeq = this.model[formSeq];

		if(!modelSeq) {
			return {};
		}

		const extraInfoModel: NFormFieldExtraInfoData = {
			comment: modelSeq.comment,
			nc: modelSeq.nc ? 1 : 0,
			photo1: modelSeq.pictures?.pic1,
			photo2: modelSeq.pictures?.pic2,
			photo3: modelSeq.pictures?.pic3,
			photo4: modelSeq.pictures?.pic4,
		};

		const returnExtraInfoModel = Object.fromEntries(
			Object.entries(extraInfoModel).filter(([entry, value]) => {
				return !!value
			})
		);

		return returnExtraInfoModel;
	}

	reset() {
		Object.keys(this.model).map(key => delete this.model[key]);
		Object.keys(this.blobPictures).map(key => delete this.blobPictures[key]);
	}

}

export interface ObjectServiceModel {
	[prop: number]: ObjectServiceModelProperties
}

interface ObjectServiceModelProperties {
	pictures?: ModelPictures;
	picturesCount?: number;
	comment?: string;
	nc?: boolean;
}

export interface NFormFieldExtraInfoData {
	comment?: string,
	photo1?: string,
	photo2?: string,
	photo3?: string,
	photo4?: string,
	nc?: 0 | 1;
}

interface ObjectServiceBlobPictures {
	[prop: number]: BlobPicturesProperties
}

export enum ObjectModel {
	comment,
	pictures
}

export interface ModelPictures {
	pic1?: string;
	pic2?: string;
	pic3?: string;
	pic4?: string;
}

export interface BlobPicturesProperties {
	blob1?: string;
	blob2?: string;
	blob3?: string;
	blob4?: string;
}
