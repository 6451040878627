import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { WebcamImage } from 'ngx-webcam';
import { NFormPhotoWebcamComponent } from './webcam/webcam.component';
import { FileUploadDataNformPhoto, NFormPhotoService } from './photo.service';
import { Subject, Subscription } from 'rxjs';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NformNonuserService } from '../../nform-nonuser.service';
import { NFormAPIJsonFormStatus } from 'src/app/api-services/portal/nform-nonuser.interface';
import { compressImage } from 'src/app/components/aws-file-upload/compress-img-file';
import { AppDeviceTypeService } from 'src/app/app.device-type.service';

@Component({
	selector: 'nform-object-photo',
	templateUrl: './photo.component.html',
	styleUrls: ['./photo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NFormPhotoComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit, OnDestroy
{

	eNFormAPIJsonFormStatus = NFormAPIJsonFormStatus;

	static nFormObjectName = 'nform-photo';
	// formControl: UntypedFormControl;
	fileInput: HTMLInputElement;
	fileUploadData: FileUploadDataNformPhoto;
	fielUploadSubscribe: Subscription;

	@ViewChild('boxdrop') boxDropFiles: ElementRef<HTMLDivElement>;

	constructor(
		public dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private nformPhotoService: NFormPhotoService,
		private datePipe: DatePipe,
		private domSanitizer: DomSanitizer,
		public nformNonuserService: NformNonuserService,
		public appDeviceTypeService: AppDeviceTypeService
	){
		super();
	}

	ngOnInit(){
		this.getImageUplodadedFromService();
	}

	ngOnDestroy(){
		if(this.fielUploadSubscribe){
			this.fielUploadSubscribe.unsubscribe();
		}
	}

	getImageUplodadedFromService(){

		const modelValue = this.formControl.value;
		const fileUploadData = this.nformPhotoService.photos[modelValue];

		if(fileUploadData){
			this.setFileUploadData(fileUploadData);
			this.subscribeUpload(this.nformPhotoService.photosObservables[fileUploadData.file.name]);
		}

		// due to props get populated by Formly after view init
		setTimeout(() => {
			if(this.field.props?.readonly && modelValue){
				this.setFileUploadData({
					done: true,
					fail: false,
					file: null,
					generatedImage: null,
					inProgress: false,
					progress: 100,
					generatedImageSanatizedUrl:
						this.domSanitizer.bypassSecurityTrustResourceUrl(modelValue),
				});
			}
		});

	}

	setFileUploadData(fileUploadData: FileUploadDataNformPhoto){
		this.fileUploadData = fileUploadData;
		this.field.data.fileUploadData = fileUploadData;
	}

	setValue(){

		const dialogRef = this.dialog.open(NFormPhotoWebcamComponent);

		dialogRef.afterClosed().subscribe(result => {

			if(result){
				this.nformPhotoService
					.createBlobImageFileAndShow(result as WebcamImage)
					.subscribe(fileUploadData => {

						this.setFileUploadData(fileUploadData);

						if(this.formControl.value !== this.fileUploadData.file.name){
							this.formControl.setValue(this.fileUploadData.file.name);
						}

						this.cdr.detectChanges();

					});
			}

		});

	}

	setTouched(){
		this.formControl.markAllAsTouched();
	}

	async handleFileInput(event){

		this.fileInput = event.target;

		const file: File = event.target.files[0] as File;
		let fileName = null;

		if(file){

			if(this.field.nform){
				fileName =
					this.field.nform.customer_code
					+ '.' + this.field.nform.site_id
					+ '.' + this.field.nform.product_code
					+ '.' + this.field.nform.serial_code
					+ '.' + this.field.nform.form.custom_form_type
					+ '.' + this.field.nform.form.custom_form_code
					+ '.' + this.field.nform.form.custom_form_version
					+ '.' + this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH.mm.ss.SSS')
					+ '.jpg';
			}

			compressImage(file, 1, .4).then(
				fileCompressed => {
					this.subscribeUpload(this.nformPhotoService.uploadFile(fileCompressed, fileName));
				}
			).catch(error => console.log(error));

		}

	}

	retry(){
		this.subscribeUpload(this.nformPhotoService.uploadFile(this.fileUploadData));
	}

	subscribeUpload(upload: Subject<FileUploadDataNformPhoto>){

		if(this.fielUploadSubscribe){
			this.fielUploadSubscribe.unsubscribe();
		}

		this.fielUploadSubscribe = upload.subscribe(

			fileUploadData => {

				this.setFileUploadData(fileUploadData);

				if(this.formControl.value !== this.fileUploadData.file.name){
					this.formControl.setValue(this.fileUploadData.file.name);
				}

				if(fileUploadData.done || fileUploadData.fail){
					this.formControl.updateValueAndValidity();
				}

				this.formControl.markAllAsTouched();
				this.cdr.detectChanges();

			},

			error => {
				// this.formControl.setErrors({uploadFailed: true});
				this.formControl.updateValueAndValidity();
				this.formControl.markAllAsTouched();
				this.cdr.detectChanges();
				console.log('photoComponent upload fail', this.formControl);
			}

		);

	}

	delete(){
		if(this.fileInput){
			this.fileInput.value = null;
		}
		this.nformPhotoService.delete(this.fileUploadData);
		this.setFileUploadData(null);
		this.formControl.reset();
	}

	onFilesDrop(files: File[]){

		if(!files.length){
			return;
		}

		this.handleFileInput({
			target: {
				files: [files[0]]
			}
		})

	}

}
