import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { MatDialog } from '@angular/material/dialog';
import { NFormPictureImgGridDialogComponent } from './img-grid-dialog/img-grid-dialog.component';
import { AppDeviceTypeService, AppDeviceType } from 'src/app/app.device-type.service';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'nform-object-picture',
	templateUrl: './picture.component.html',
	styleUrls: ['./picture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NFormPictureComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	// implements OnInit
{

	static nFormObjectName = 'nform-picture';

	// formControl: UntypedFormControl;
	self: NFormPictureComponent = this;
	device: AppDeviceType;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private cdr: ChangeDetectorRef,
		public dialog: MatDialog,
		public appDeviceTypeService: AppDeviceTypeService
	){
		super();
		this.getDevice();
	}

	getDevice(){
		this.device = this.appDeviceTypeService.device;
		this.appDeviceTypeService
			.getDeviceTypeObservable().subscribe(device => {
				this.device = device;
				this.cdr.detectChanges();
			});
	}

	reset(){
		if(this.model[this.key as string]){
			this.model[this.key as string].splice(0);
		}
		this.formControl.setValue(this.model[this.key as string]);
	}

	openDialog(){

		if(!this.device.phone || this.formControl.disabled){
			return;
		}

		if(this.document.body.requestFullscreen){
			this.document.body.requestFullscreen().then(
				result => console.log(result),
				error => console.log(error)
			);
		}

		this.dialog.open(
			NFormPictureImgGridDialogComponent,
			{
				data: {
					formlyField: this
				},
				panelClass: 'dialog-panel-nform-picture-dialog'
			}
		);

	}

}
