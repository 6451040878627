<mat-dialog-content>
	<div
		layout="column"
		layout-align="center center"
		position-relative
	>

		<button mat-dialog-close color="primary" class="close-dialog" mat-mini-fab>
			<mat-icon svgIcon="close"></mat-icon>
		</button>

		<webcam
			*ngIf="showWebcam && !webcamImage"
			[height]="500"
			[width]="500"
			[trigger]="triggerObservable"
			(imageCapture)="handleImage($event)"
			[allowCameraSwitch]="allowCameraSwitch"
			[switchCamera]="nextWebcamObservable"
			[videoOptions]="videoOptions"
			[imageQuality]="1"
			(cameraSwitched)="cameraWasSwitched($event)"
			(initError)="handleInitError($event)"
		>
		</webcam>

		<div class="snapshot" *ngIf="webcamImage">
			<img [src]="webcamImage.imageAsDataUrl"/>
			<!-- <img *ngIf="base64TrimmedURL" [src]="sanatizeUrl(generatedImage)"> -->
		</div>

		<div
			*ngIf="!webcamImage"
			layout="row"
			layout-align="center"
			layout-margin
			class="webcam-actions"
		>
			<button (click)="triggerSnapshot();" flex="none" layout-align="center" mat-fab color="primary">
				<mat-icon svgIcon="camera_take_picture"></mat-icon>
			</button>
		</div>

		<div
			*ngIf="webcamImage"
			layout="row"
			layout-align="center"
			layout-margin
			class="webcam-actions"
		>
			<button (click)="rejectImage()" flex="none" layout-align="center" mat-fab color="warn">
				<mat-icon svgIcon="close"></mat-icon>
			</button>
			<button [mat-dialog-close]="webcamImage" flex="none" layout-align="center" mat-fab theme-palette-hue-bg="accent-dark">
				<mat-icon svgIcon="done"></mat-icon>
			</button>
		</div>

	</div>
</mat-dialog-content>
