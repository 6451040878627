import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Observer, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { APIuploadAwsFileWebappService, FileUploadData } from 'src/app/api-services/portal/upload-aws-file-webapp.service';

@Injectable({
	providedIn: 'root'
})
export class NFormPhotoService {

	// [todo] clean photos after send form
	photos: {
		[prop: string]: FileUploadDataNformPhoto;
	} = {};

	photosObservables: {
		[prop: string]: Subject<FileUploadDataNformPhoto>;
	} = {};

	private pendingFiles = new Subject();

	constructor(
		private apiUploadAwsFileWebappService: APIuploadAwsFileWebappService,
		private domSanitizer: DomSanitizer,
		private translocoService: TranslocoService
	) { }

	createBlobImageFileAndShow(webcamImage: WebcamImage): Observable<FileUploadDataNformPhoto> {

		return new Observable(subscriber => {

			this.dataURItoBlob(webcamImage.imageAsBase64).subscribe((blob: Blob) => {

				const imageBlob: Blob = blob;
				const imageName: string = this.generateName();
				const imageFile: File = new File([imageBlob], imageName, {type: 'image/jpeg'});

				const fileUploadData = this.getFileUploadData(imageFile);

				this.apiUploadAwsFileWebappService.upload(
					fileUploadData,
					{
						extras: {
							friendlyMessage: {
								payloadError: {
									message: this.translocoService.translate('Components.NForm.PHOTO.upload-payload-error')
								},
								serverError: {
									message: this.translocoService.translate('Components.NForm.PHOTO.upload-server-error')
								}
							}
						}
					}
				)
					.pipe(
						tap(() => {
							subscriber.next(fileUploadData);
						})
					)
					.subscribe(
						(event: any) => {
							if (typeof (event) === 'object') {
								subscriber.complete();
								this.notifyPendingFilesDone();
							}
						},
						error => subscriber.error(error)
					);

			});

		});

	}

	uploadFile(file: File | FileUploadDataNformPhoto, name: string = null): Subject<FileUploadDataNformPhoto> {

		const fileUploadData = this.getFileUploadData(file, name);

		const subscriber = new Subject<FileUploadDataNformPhoto>();

		// initial notification
		subscriber.next(fileUploadData);

		this.apiUploadAwsFileWebappService.upload(
			fileUploadData,
			{
				extras: {
					friendlyMessage: {
						payloadError: {
							message: this.translocoService.translate('Components.NForm.PHOTO.upload-payload-error')
						},
						serverError: {
							message: this.translocoService.translate('Components.NForm.PHOTO.upload-server-error')
						}
						// serverError: false
					}
				}
			}
		)
			.pipe(
				tap(() => {
					subscriber.next(fileUploadData);
				})
			)
			.subscribe(
				(event: any) => {
					if (typeof (event) === 'object') {
						subscriber.complete();
						this.notifyPendingFilesDone();
					}
				},
				error => {
					subscriber.error(error);
					this.notifyPendingFilesError(fileUploadData);
				}
			);

		this.photosObservables[fileUploadData.file.name] = subscriber;

		return subscriber;

	};

	delete(fileUploadData: FileUploadDataNformPhoto){
		delete this.photos[fileUploadData.file.name];
	}

	getFileUploadData(file: File | FileUploadDataNformPhoto, name: string = null): FileUploadDataNformPhoto {

		if(!(file instanceof File)){
			Object.assign(
				file,
				{
					done: false,
					fail: false,
					inProgress: true,
					progress: 0
				}
			);

			return file;
		}

		const newBlob = file.slice(0, file.size, file.type);
		const newFile = new File(
			[newBlob],
			name || this.generateName(file.name),
			{type: file.type}
		);

		const generatedImage = window.URL.createObjectURL(newFile);

		const fileUploadData: FileUploadDataNformPhoto = {
			done: false,
			fail: false,
			inProgress: true,
			file: newFile,
			generatedImage,
			generatedImageSanatizedUrl: this.sanatizeUrl(generatedImage),
			progress: 0
		};

		this.photos[newFile.name] = fileUploadData;

		return fileUploadData;

	}

	// [todo] generate name based on form properties
	generateName(originalFileName: string = ''): string {
		const date: number = new Date().valueOf();
		let text = '';

		const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 5; i++) {
			text += possibleText.charAt(
				Math.floor(Math.random() * possibleText.length)
			);
		}
		// Replace extension according to your media type like this
		return `${date}.${text}.${originalFileName}.jpeg`;
	}


	sanatizeUrl(generatedImageUrl): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(generatedImageUrl);
	}

	/* Method to convert Base64Data Url as Image Blob */
	dataURItoBlob(dataURI: string): Observable<Blob> {
		return new Observable((observer: Observer<Blob>) => {
			const byteString: string = window.atob(dataURI);
			const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
			const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
				int8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([int8Array], { type: 'image/jpeg' });
			observer.next(blob);
			observer.complete();
		});
	}

	subscribeForPendingFiles(): Subject<any> {
		return this.pendingFiles;
	}

	hasPendingFiles(): boolean {
		return Object.entries(this.photos)
			.filter(entry => !entry[1].done && !entry[1].fail).length > 0;
	}

	reset() {
		this.photos = {};
	}

	private notifyPendingFilesDone(){
		if(!this.hasPendingFiles()){
			this.pendingFiles.next(true);
		}
		this.notifyAllPendingFilesComplete();
	}

	private notifyPendingFilesError(fileUploaData: FileUploadDataNformPhoto){
		this.pendingFiles.next(fileUploaData);
		this.notifyAllPendingFilesComplete();
	}

	private notifyAllPendingFilesComplete(){
		if(
			Object.entries(this.photos)
				.filter(entry => entry[1].inProgress).length === 0
		){
			this.pendingFiles.next(NFormPhotoServiceEnum.pendingFilesComplete);
		}
	}

}

export interface FileUploadDataNformPhoto extends FileUploadData {
	generatedImage: string;
	generatedImageSanatizedUrl: SafeResourceUrl;
}

export enum NFormPhotoServiceEnum {
	pendingFilesComplete = 'complete'
}
