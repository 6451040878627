<div
	layout="column"
	[ngClass]="{
		'ng-touched': formControl.touched,
		'ng-invalid': formControl.invalid,
		'ng-untouched': formControl.untouched
	}"
	position-relative
>

	<p
		*ngIf="to.floatLabel === 'paragraph'"
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div layout="row">
		<datetime-picker
			#dateTimePicker
			[datePickerDefaultValue]="datePlaceHolder"
			[inlineTimepicker]="true"
			(timePickerChange)="onTimePickerChange($event)"
			[showResetTimePickerFormControlButton]="!to.required ? true : false"
			[disabled]="field.props?.readonly"
		>
		</datetime-picker>
	</div>


	<div class="mat-mdc-form-field-subscript-wrapper">
		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>
	</div>

	<div
		*ngIf="to.required"
		class="mat-mdc-form-field-subscript-wrapper fixed-required"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</div>
