<div layout="column">

	<p
		*ngIf="to.floatLabel === 'paragraph'"
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<mat-hint
		*ngIf="field.data?.rule?.last_measure_value"
		class="mat-small"
	>
		{{'Route.Nform-nonuser.Objects.Number.HintMeasure' | transloco}}
		{{field.data.rule.last_measure_value}} {{field.data.rule.value_sufix}}
		-
		{{field.data.rule.last_measure_date | dateParseString: 'oracle1' | date:'dd/MM/yyyy HH:mm'}}
	</mat-hint>

	<mat-form-field
		[floatLabel]="to.floatLabel || 'auto'"
		[hideRequiredMarker]="true"
		override-mat-form-field-outline-thick
	>

		<mat-label
			*ngIf="to.floatLabel !== 'paragraph' && to.label && to.hideLabel !== true"
		>
			<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
			{{ to.label }}
			<span
				*ngIf="to.required"
				theme-palette-hue-fg="warn"
			>
				*
			</span>
		</mat-label>

		<input
			matInput
			#ipt
			[id]="id"
			type="text"
			[readonly]="to.readonly"
			[required]="to.required"
			[formControl]="_formControl"
			[formlyAttributes]="field"
			[tabIndex]="to.tabindex"
			[placeholder]="to.placeholder"
			[mask]="'separator.' + decimal"
			[attr.maxlength]="maxLength"
			[thousandSeparator]="thousandSeparator"
			[autocomplete]="to.autocomplete ? 'off' : 'on' "
			[allowNegativeNumbers]="true"
		/>

		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>

		<mat-hint
			align="end"
		>
			{{ipt.value.length}} / {{maxLength}}
		</mat-hint>

		<div
			*ngIf="to.required"
			class="mat-mdc-form-field-subscript-wrapper fixed-required"
			theme-palette-hue-fg="warn"
		>
			{{'Form.ValidationMessage.required' | transloco}}
		</div>

	</mat-form-field>

</div>
