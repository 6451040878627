import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { ApiUrls } from 'src/api-urls/urls';
import { APIPortalNFormPostResponse, NFormAPIRoot } from './nform-nonuser.interface';

@Injectable({
	providedIn: 'root'
})
export class APIportalNformNonuserService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) {}

	get(
		attrs
	): Observable<HttpResponse<NFormAPIRoot>>{

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.nformNonuser,
			headers: attrs.headers || {},
			extras: attrs.extras || {},
			params: attrs.params || {}
		});

	}

	post(
		data,
		attrs
	): Observable<HttpResponse<APIPortalNFormPostResponse>>{

		return this.appHttpRequestService.getRequest({
			method: 'post',
			url: ApiUrls.portal.nformNonuser,
			payload: data,
			extras: attrs.extras || {},
			params: attrs.params || {}
		});

	}


}
