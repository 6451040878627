import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { formatDate } from '@angular/common';

@Component({
	selector: 'nform-object-date',
	templateUrl: './date.component.html',
	styleUrls: ['./date.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NFormDateComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit
{

	static nFormObjectName = 'nform-date';

	// _formControl: UntypedFormControl = this.formControl as FormControl<any>;
	datePickerFormControl = new UntypedFormControl();

	constructor(
		@Inject(LOCALE_ID) private locale: string
	){
		super();
	}

	ngOnInit(){
		this.setDatePickerFormControl();
	}

	dataChanged(isResetDatePickerFormControl: boolean = false){
		this.setModel(isResetDatePickerFormControl);
		console.log(
			'dataChanged',
			this.datePickerFormControl.value,
			this.model[this.key as string]
		);
	}

	setModel(isResetDatePickerFormControl: boolean = false){
		if(isResetDatePickerFormControl) {
			this.datePickerFormControl.markAllAsTouched();
			this.datePickerFormControl.setValue(null);
			this.formControl.setValue(null);
		} else {
			this.formControl.setValue(
				formatDate(this.datePickerFormControl.value, 'yyyy-MM-dd', this.locale)
			);
		}
	}

	private setDatePickerFormControl(){
		if(this.formControl.value){
			this.datePickerFormControl.setValue(
				this.parseDate(this.formControl.value)
			);
		}
	}

	private parseDate(date: string): Date{
		const split = date.split('-');
		return new Date(
			parseInt(split[0], 10),
			parseInt(split[1] , 10) - 1,
			parseInt(split[2], 10),
			0,
			0,
			0
		);
	}
}
