import { Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import {
	nFormCHARFormlyFieldConstructor,
	nFormCHECKBOXFormlyFieldConstructor,
	nFormLABELFormlyFieldConstructor,
	nFormDATEFormlyFieldConstructor,
	nFormNUMBERFormlyFieldConstructor,
	nFormPHOTOFormlyFieldConstructor,
	nFormRATINGBARFormlyFieldConstructor,
	nFormRATINGIMAGEFormlyFieldConstructor,
	nFormHOURFormlyFieldConstructor,
	nFormCOMBOBOXFormlyFieldConstructor,
	nFormPICTUREFormlyFieldConstructor
} from './objects/formly-field-constructor';
import { NFormAPIData, NFormAPIJsonField, NFormAPIJsonFormDataFields, NFormObjects } from 'src/app/api-services/portal/nform-nonuser.interface';
import { APIportalNformNonuserService } from 'src/app/api-services/portal/nform-nonuser.service';
import { NFormNonuserTabsControl, NFormNonuserTabsControlTab, NFormObjectFormlyFieldConfig } from './nform-nonuser.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ObjectExtraInfoService } from './object-wrapper/object-extra-info/object-extra-info.service';
import { NFormPhotoService } from './objects/photo/photo.service';

@Injectable({
	providedIn: 'root'
})

export class NformNonuserService {

	private objectsConstructor = {
		[NFormObjects.CHAR]: nFormCHARFormlyFieldConstructor,
		[NFormObjects.NUMBER]: nFormNUMBERFormlyFieldConstructor,
		[NFormObjects.PHOTO]: nFormPHOTOFormlyFieldConstructor,
		[NFormObjects.CHECKBOX]: nFormCHECKBOXFormlyFieldConstructor,
		[NFormObjects.RATINGIMAGE]: nFormRATINGIMAGEFormlyFieldConstructor,
		[NFormObjects.RATINGBAR]: nFormRATINGBARFormlyFieldConstructor,
		[NFormObjects.LABEL]: nFormLABELFormlyFieldConstructor,
		[NFormObjects.DATE]: nFormDATEFormlyFieldConstructor,
		[NFormObjects.HOUR]: nFormHOURFormlyFieldConstructor,
		[NFormObjects.COMBOBOX]: nFormCOMBOBOXFormlyFieldConstructor,
		[NFormObjects.PICTURE]: nFormPICTUREFormlyFieldConstructor
	};

	currentPath: string;
	qrcode: string;
	process: string;
	doNotRequestLogin = false;
	qrcodeError: any;
	apiData: NFormAPIData;
	apiTranslates: any;
	apiError: any;
	translatesAvailable: any = {};
	currentTranslate: any = {};
	startDate: Date;

	model: {
		[prop: string]: any;
	} = {};

	mapOfNFormFields: {
		[fieldSec: string]: NFormAPIJsonField;
	} = {};

	mapOfNFormFieldsData: {
		[fieldSec: string]: NFormAPIJsonFormDataFields;
	} = {};

	tabsControl: NFormNonuserTabsControl;

	setTabObserver: Subject<number> = new Subject<number>();

	sideNavRightContainer: ViewContainerRef;
	matSideNavRight: MatSidenav;

	isRunningInsideSideNav = false;

	delegateCurrentFormControlErrors = (): number => 0;

	constructor(
		private apiPortalNformNonuserService: APIportalNformNonuserService,
		private authService: AuthService,
		private router: Router,
		private objectExtraInfoService: ObjectExtraInfoService,
		private nformPhotoService: NFormPhotoService,
	) {
		this.initTabsControl();
	}

	loadNForm(){

		return new Promise((resolve, reject) => {

			const dataSubscribe = this.apiPortalNformNonuserService.get({
				headers: {
					nform: JSON.stringify({
						P_BARCODE_ENC: this.qrcode,
						P_PROCESS: this.process
					})
				}
			})

			dataSubscribe.subscribe(

				apiData => {

					this.apiData = apiData.body.data;
					this.apiTranslates = apiData.body.translates;

					this.setCurrentTranslate();

					resolve(true);

				},

				error => {
					this.apiError = error;
					reject();
				}

			);

		});

	}

	initWithTranslate(){
		this.reset();
		return new Promise((resolve, reject) => {
			this.setTabs();
			this.setTabsControl();
			this.setTabsFields();
			this.fillModelWithFieldsData();
			resolve(true);
		});
	}

	setTabsControl(){
		this.tabsControl.total = this.apiData.form.tab.length;
		this.selectTab(1);
	}

	setTabErrors(n: number, totalErrors: number){
		const tab = this.tabsControl.tabs[n-1];

		if(tab && totalErrors >= 0){
			tab.errors = totalErrors;
		}

		this.setTabsErrors();
	}

	setTabsErrors(includeNonVisited = false){
		this.tabsControl.errors = 0;
		this.tabsControl.tabs.map(tab =>
			includeNonVisited || tab.visited
				? this.tabsControl.errors += tab.errors
				: null
		);
	};

	selectTab(n: number){

		const currentTab = this.tabsControl.tabs[this.tabsControl.current-1];
		const newTab = this.tabsControl.tabs[n-1];

		if(newTab && n !== this.tabsControl.current && (n > 0 || n <= this.tabsControl.total)){

			if(currentTab){

				currentTab.current = false;
				currentTab.visited = true;

				this.setTabErrors(
					this.tabsControl.current,
					this.delegateCurrentFormControlErrors()
				);

			}

			this.tabsControl.current = n;

			newTab.current = true;

			this.setTabsErrors();

			this.setTabObserver.next(n);

			if(!this.isRunningInsideSideNav){
				this.router.navigate(
					[
						this.currentPath,
						n
					],
					{
						queryParamsHandling: 'preserve'
					}
				);
			}

		}

	}

	nextTab(){
		if(this.tabsControl.current < this.tabsControl.total){
			this.selectTab(this.tabsControl.current+1);
		}
	}

	previousTab(){
		if(this.tabsControl.current > 1){
			this.selectTab(this.tabsControl.current-1);
		}
	}

	validNonVisitedTabs(){
		this.tabsControl.tabs.map((tab, index) => {
			if(!tab.visited){

				tab.errors = 0;

				tab.fields.map(field => {
					if(
						field.templateOptions.required
						&&
						typeof this.model[field.key as string] == 'undefined'
					){
						tab.errors += 1;
					}
				});

				tab.visited = true;

			}
		});
	}

	// setCurrentTranslate(lang = this.translocoService.getActiveLang()){

	// 	// set first language as default when there is no user selected translate in form
	// 	if(!this.apiTranslates[lang]){
	// 		lang = Object.keys(this.apiTranslates)[0];
	// 	}

	// 	this.i18nService.updateLocale(lang, true);
	// 	this.currentTranslate = this.apiTranslates[lang];
	// 	this.mapTranslates();

	// };

	setCurrentTranslate(
		langCode: number = this.authService.getTokenInfo().translate_code
	){

		// set first language as default when there is no user selected translate in form
		this.currentTranslate = this.apiTranslates[langCode];

		if(!this.currentTranslate){
			langCode = parseInt(Object.keys(this.apiTranslates)[0], 10);
			this.currentTranslate = this.apiTranslates[langCode];
		}

		this.mapTranslates(langCode);

	};

	reset(){
		this.mapOfNFormFieldsData = {};
		Object.keys(this.model).map(key => delete this.model[key]);
		this.initTabsControl();
		this.objectExtraInfoService.reset();
		this.nformPhotoService.reset();

	}

	private initTabsControl(){

		const newTabsControl: NFormNonuserTabsControl = {
			errors: 0,
			current: -1,
			total: 1,
			tabs: []
		};

		if(!this.tabsControl){
			this.tabsControl = newTabsControl;
		}else{
			Object.assign(
				this.tabsControl,
				newTabsControl
			);
		}

	}

	private mapTranslates(langCode: number){

		// const lang = this.translocoService.getActiveLang();

		Object.assign(
			this.translatesAvailable,
			Object.fromEntries(Object.keys(this.translatesAvailable).map(
				key => [key, false]
			)),
			Object.fromEntries(Object.keys(this.apiTranslates).map(
				key => [key, true]
			))
		);

		this.apiTranslates[langCode].map = {};
		this.apiTranslates[langCode].field
			.forEach(
				(data, key) =>
					this.apiTranslates[langCode].map[data.custom_form_seq] = data
			);

	}

	private setTabs(){
		this.tabsControl.tabs = this.apiData.form.tab.map((tab, index) => ({
			data: {
				label: this.currentTranslate.map[tab.custom_form_seq].value
			},
			fields: [],
			tabNumber: (index + 1),
			current: false,
			visited: false,
			errors: 0
		}));
	}

	private setTabsFields(){

		this.mapOfNFormFields = {};

		this.apiData.form.tab.map((tab, index) => {

			this.tabsControl.tabs[index].fields = tab.field.map(field => {
				this.mapOfNFormFields[field.custom_form_seq] = field;
				return this.formlyFieldConstructor(this.tabsControl.tabs[index], field)
			})
				.filter(field => !!field);

			this.enumerateFields(this.tabsControl.tabs[index].fields);

		});

	}

	// eslint-disable-next-line max-lines-per-function
	private formlyFieldConstructor(
		tab: NFormNonuserTabsControlTab,
		field: NFormAPIJsonField
	){

		let formlyField: NFormObjectFormlyFieldConfig;

		if(this.objectsConstructor[field.custom_form_data_type]){

			formlyField = this.objectsConstructor[field.custom_form_data_type](
				this.apiData,
				field,
				this.currentTranslate.map[field.custom_form_seq]
			);

			formlyField.tab = tab;

			this.attachFormlyFieldDefaultValitador(formlyField);

			this.currentTranslate.map[field.custom_form_seq]

			this.attachFormlyFieldDataTranslate(
				formlyField,
				this.currentTranslate.map[field.custom_form_seq]
			)
		}

		return formlyField;

	}

	private enumerateFields(fields: NFormObjectFormlyFieldConfig[]){

		const allowedEnumarateTypes = [
			NFormObjects.CHAR,
			NFormObjects.NUMBER,
			NFormObjects.PHOTO,
			NFormObjects.CHECKBOX,
			NFormObjects.RATINGIMAGE,
			NFormObjects.RATINGBAR,
			NFormObjects.DATE,
			NFormObjects.HOUR,
			NFormObjects.COMBOBOX,
			NFormObjects.PICTURE
		];

		fields.reduce(
			(accumulator, currentValue) => {
				if(
					allowedEnumarateTypes.includes(currentValue?.data?.custom_form_data_type)
				){
					accumulator+=1;
					currentValue.data.enumarated = accumulator;
				}
				return accumulator;
			},
			0
		);

	}

	private attachFormlyFieldDefaultValitador(
		formlyField: NFormObjectFormlyFieldConfig
	){

		formlyField.asyncValidators = {
			validation: [this.validAndCountErrorsWhenVisited]
		};

		formlyField.hooks = this.getFieldHooks();

	}

	private attachFormlyFieldDataTranslate(
		formlyField: NFormObjectFormlyFieldConfig,
		translate: any
	) {
		formlyField.dataTranslate = translate;
	}

	private validAndCountErrorsWhenVisited = (c: UntypedFormControl): ValidationErrors =>
		new Promise((resolve, reject) => {
			setTimeout(() => {

				this.setTabErrors(
					this.tabsControl.current,
					this.delegateCurrentFormControlErrors()
				);

				resolve(true);

			}, 0);
		});

	private getFieldHooks(){
		return {
			afterViewInit: (field: NFormObjectFormlyFieldConfig) => {

				if(field.tab.visited){
					field.formControl.markAsTouched();
					field.formControl.asyncValidator(field.formControl);
				}

				field.formControl.statusChanges
					.pipe()
					.subscribe(value => {
						if(field.formControl.asyncValidator){
							field.formControl.asyncValidator(field.formControl);
						}
					});
			}
		};
	}

	private fillModelWithFieldsData(){
		if(this.apiData.form_data?.data_fields?.length){
			this.apiData.form_data?.data_fields.forEach(field => {

				this.mapOfNFormFieldsData[field.custom_form_seq] = field;

				if(this.mapOfNFormFields[field.custom_form_seq].custom_form_data_type === NFormObjects.RATINGBAR){
					this.model[field.custom_form_seq] = parseInt(field.data_value, 10);
				}else if(this.mapOfNFormFields[field.custom_form_seq].custom_form_data_type === NFormObjects.PICTURE){
					this.model[field.custom_form_seq] = field.data_value?.split('|') || [];
				}else{
					this.model[field.custom_form_seq] = field.data_value;
				}

				this.objectExtraInfoService.model[field.custom_form_seq] = {
					nc: field.data_nc,
					comment: field.data_comment,
					pictures: {
						pic1: field.data_photo1_url,
						pic2: field.data_photo2_url,
						pic3: field.data_photo3_url,
						pic4: field.data_photo4_url
					}
				};

				this.objectExtraInfoService.blobPictures[field.custom_form_seq] = {
					blob1: field.data_photo1_url,
					blob2: field.data_photo2_url,
					blob3: field.data_photo3_url,
					blob4: field.data_photo4_url
				}

				this.objectExtraInfoService.photoCount(
					field.custom_form_seq,
					field
				);

				this.tabsControl.tabs.forEach(tab => {
					tab.visited = true;
				});

			});
		}
	}

}
